import { Toast, ERROR } from '@/modules/toast/handler/handlerToast';
import { MAIN_TOKEN_ADDRESS } from '@/core/helpers/common';
import BigNumber from 'bignumber.js';
import {
  formatFiatValue,
  formatFloatingPointValue,
  formatIntegerValue
} from '@/core/helpers/numberFormatHelper';
import { toBN } from 'web3-utils';
import WALLET_TYPES from '@/modules/access-wallet/common/walletTypes';
import { fromBase } from '../../helpers/unit';

const setCurrency = async function ({ commit }, val) {
  fetch('https://mainnet.mewwallet.dev/v2/prices/exchange-rates')
    .then(res => res.json())
    .then(rates => {
      const currentRate = rates
        ? rates.find(rate => rate.fiat_currency === val)
        : {};
      commit('SET_CURRENCY_RATE', {
        data: currentRate,
        timestamp: Date.now()
      });
    })
    .catch(e => {
      commit('SET_CURRENCY_RATE', {
        data: {},
        timestamp: Date.now()
      });
      Toast(e.message, {}, ERROR);
    });
};
const setLastPath = function ({ commit }, val) {
  commit('SET_LAST_PATH', val);
};
const setCoinGeckoTokens = function ({ commit }, params) {
  commit('SET_COIN_GECKO_TOKENS', params);
};
const setNetworkTokens = function ({ commit }, params) {
  commit('SET_NETWORK_TOKENS', params);
};
const setTokenAndEthBalance = function ({
  rootGetters,
  getters,
  dispatch,
  commit,
  rootState
}) {
  commit('wallet/SET_LOADING_WALLET_INFO', true, { root: true });
  const network = rootGetters['global/network'];
  const address = rootState.wallet.address;

  const _formatBalance = (balance, decimals) => {
    let n = new BigNumber(balance);
    if (decimals) {
      n = formatFloatingPointValue(fromBase(n, decimals));
    } else {
      n = formatIntegerValue(n);
    }
    return n;
  };

  const _getBalance = () => {
    rootState.wallet.web3.eth.getBalance(address).then(res => {
      const token = getters.contractToToken(MAIN_TOKEN_ADDRESS);

      const usdBalance = new BigNumber(fromBase(res, token.decimals))
        .times(token.price)
        .toString();
      dispatch(
        'wallet/setTokens',
        [
          Object.assign(
            {
              balance: res,
              balancef: _formatBalance(res, token.decimals).value,
              usdBalance: usdBalance,
              usdBalancef: formatFiatValue(usdBalance).value
            },
            token
          )
        ],
        { root: true }
      )
        .then(() =>
          dispatch('wallet/setAccountBalance', toBN(res), { root: true })
        )
        .then(() => {
          // dispatch can't be blank
          dispatch('custom/updateCustomTokenBalances', false, { root: true });
          commit('wallet/SET_LOADING_WALLET_INFO', false, { root: true });
        });
    });
  };

  const currentProvider = rootState.wallet.web3.eth.currentProvider;
  // Prevent the 'Invalid return values' error
  // when accessing new network on MetaMask
  if (
    rootState.wallet.identifier === WALLET_TYPES.WEB3_WALLET &&
    network.chainId !== parseInt(currentProvider.chainId)
  ) {
    return;
  }
  if (
    rootState.wallet.identifier !== WALLET_TYPES.WEB3_WALLET &&
    currentProvider.connection
  ) {
    const currentProviderUrl = currentProvider.connection.url;
    if (network.url !== currentProviderUrl) {
      dispatch('wallet/setWeb3Instance', undefined, { root: true });
    }
  }
  _getBalance();
};

const storeEIP6963Wallet = function ({ commit }, params) {
  commit('STORE_EIP6963_WALLET', params);
};
const setSelectedEIP6963Provider = function ({ commit }, params) {
  commit('SET_SELECTED_EIP6963_PROVIDER', params);
};

const setSelectedEIP6963Info = function ({ commit }, params) {
  commit('SET_SELECTED_EIP6963_INFO', params);
};
export default {
  setLastPath,
  setCurrency,
  setCoinGeckoTokens,
  setTokenAndEthBalance,
  setNetworkTokens,
  storeEIP6963Wallet,
  setSelectedEIP6963Info,
  setSelectedEIP6963Provider
};
